// countryCodeOptions.js
export const countryCodeOptions = [
  { name: 'Afghanistan', code: '+93', iso2: 'AF' },
  { name: 'Albania', code: '+355', iso2: 'AL' },
  { name: 'Algeria', code: '+213', iso2: 'DZ' },
  { name: 'American Samoa', code: '+1-684', iso2: 'AS' },
  { name: 'Andorra', code: '+376', iso2: 'AD' },
  { name: 'Angola', code: '+244', iso2: 'AO' },
  { name: 'Argentina', code: '+54', iso2: 'AR' },
  { name: 'Armenia', code: '+374', iso2: 'AM' },
  { name: 'Australia', code: '+61', iso2: 'AU' },
  { name: 'Austria', code: '+43', iso2: 'AT' },
  { name: 'Azerbaijan', code: '+994', iso2: 'AZ' },
  { name: 'Bangladesh', code: '+880', iso2: 'BD' },
  { name: 'Barbados', code: '+1-246', iso2: 'BB' },
  { name: 'Belgium', code: '+32', iso2: 'BE' },
  { name: 'Belize', code: '+501', iso2: 'BZ' },
  { name: 'Benin', code: '+229', iso2: 'BJ' },
  { name: 'Bermuda', code: '+1-441', iso2: 'BM' },
  { name: 'Bhutan', code: '+975', iso2: 'BT' },
  { name: 'Bolivia', code: '+591', iso2: 'BO' },
  { name: 'Bosnia and Herzegovina', code: '+387', iso2: 'BA' },
  { name: 'Brazil', code: '+55', iso2: 'BR' },
  { name: 'Brunei', code: '+673', iso2: 'BN' },
  { name: 'Bulgaria', code: '+359', iso2: 'BG' },
  { name: 'Burkina Faso', code: '+226', iso2: 'BF' },
  { name: 'Burundi', code: '+257', iso2: 'BI' },
  { name: 'Cambodia', code: '+855', iso2: 'KH' },
  { name: 'Cameroon', code: '+237', iso2: 'CM' },
  { name: 'Canada', code: '+1', iso2: 'CA' },
  { name: 'Chile', code: '+56', iso2: 'CL' },
  { name: 'China', code: '+86', iso2: 'CN' },
  { name: 'Colombia', code: '+57', iso2: 'CO' },
  { name: 'Costa Rica', code: '+506', iso2: 'CR' },
  { name: 'Croatia', code: '+385', iso2: 'HR' },
  { name: 'Cuba', code: '+53', iso2: 'CU' },
  { name: 'Cyprus', code: '+357', iso2: 'CY' },
  { name: 'Czech Republic', code: '+420', iso2: 'CZ' },
  { name: 'Denmark', code: '+45', iso2: 'DK' },
  { name: 'Dominican Republic', code: '+1-809', iso2: 'DO' },
  { name: 'Ecuador', code: '+593', iso2: 'EC' },
  { name: 'Egypt', code: '+20', iso2: 'EG' },
  { name: 'El Salvador', code: '+503', iso2: 'SV' },
  { name: 'Estonia', code: '+372', iso2: 'EE' },
  { name: 'Ethiopia', code: '+251', iso2: 'ET' },
  { name: 'Fiji', code: '+679', iso2: 'FJ' },
  { name: 'Finland', code: '+358', iso2: 'FI' },
  { name: 'France', code: '+33', iso2: 'FR' },
  { name: 'Georgia', code: '+995', iso2: 'GE' },
  { name: 'Germany', code: '+49', iso2: 'DE' },
  { name: 'Ghana', code: '+233', iso2: 'GH' },
  { name: 'Greece', code: '+30', iso2: 'GR' },
  { name: 'Hong Kong', code: '+852', iso2: 'HK' },
  { name: 'Hungary', code: '+36', iso2: 'HU' },
  { name: 'Iceland', code: '+354', iso2: 'IS' },
  { name: 'India', code: '+91', iso2: 'IN' },
  { name: 'Indonesia', code: '+62', iso2: 'ID' },
  { name: 'Iran', code: '+98', iso2: 'IR' },
  { name: 'Iraq', code: '+964', iso2: 'IQ' },
  { name: 'Ireland', code: '+353', iso2: 'IE' },
  { name: 'Israel', code: '+972', iso2: 'IL' },
  { name: 'Italy', code: '+39', iso2: 'IT' },
  { name: 'Jamaica', code: '+1-876', iso2: 'JM' },
  { name: 'Japan', code: '+81', iso2: 'JP' },
  { name: 'Jordan', code: '+962', iso2: 'JO' },
  { name: 'Kazakhstan', code: '+7', iso2: 'KZ' },
  { name: 'Kenya', code: '+254', iso2: 'KE' },
  { name: 'Kuwait', code: '+965', iso2: 'KW' },
  { name: 'Kyrgyzstan', code: '+996', iso2: 'KG' },
  { name: 'Laos', code: '+856', iso2: 'LA' },
  { name: 'Latvia', code: '+371', iso2: 'LV' },
  { name: 'Lebanon', code: '+961', iso2: 'LB' },
  { name: 'Liberia', code: '+231', iso2: 'LR' },
  { name: 'Libya', code: '+218', iso2: 'LY' },
  { name: 'Lithuania', code: '+370', iso2: 'LT' },
  { name: 'Luxembourg', code: '+352', iso2: 'LU' },
  { name: 'Madagascar', code: '+261', iso2: 'MG' },
  { name: 'Malaysia', code: '+60', iso2: 'MY' },
  { name: 'Maldives', code: '+960', iso2: 'MV' },
  { name: 'Mali', code: '+223', iso2: 'ML' },
  { name: 'Malta', code: '+356', iso2: 'MT' },
  { name: 'Mexico', code: '+52', iso2: 'MX' },
  { name: 'Monaco', code: '+377', iso2: 'MC' },
  { name: 'Mongolia', code: '+976', iso2: 'MN' },
  { name: 'Morocco', code: '+212', iso2: 'MA' },
  { name: 'Mozambique', code: '+258', iso2: 'MZ' },
  { name: 'Myanmar', code: '+95', iso2: 'MM' },
  { name: 'Namibia', code: '+264', iso2: 'NA' },
  { name: 'Nepal', code: '+977', iso2: 'NP' },
  { name: 'Netherlands', code: '+31', iso2: 'NL' },
  { name: 'New Zealand', code: '+64', iso2: 'NZ' },
  { name: 'Nicaragua', code: '+505', iso2: 'NI' },
  { name: 'Nigeria', code: '+234', iso2: 'NG' },
  { name: 'Norway', code: '+47', iso2: 'NO' },
  { name: 'Oman', code: '+968', iso2: 'OM' },
  { name: 'Pakistan', code: '+92', iso2: 'PK' },
  { name: 'Panama', code: '+507', iso2: 'PA' },
  { name: 'Paraguay', code: '+595', iso2: 'PY' },
  { name: 'Peru', code: '+51', iso2: 'PE' },
  { name: 'Philippines', code: '+63', iso2: 'PH' },
  { name: 'Poland', code: '+48', iso2: 'PL' },
  { name: 'Portugal', code: '+351', iso2: 'PT' },
  { name: 'Qatar', code: '+974', iso2: 'QA' },
  { name: 'Romania', code: '+40', iso2: 'RO' },
  { name: 'Russia', code: '+7', iso2: 'RU' },
  { name: 'Saudi Arabia', code: '+966', iso2: 'SA' },
  { name: 'Senegal', code: '+221', iso2: 'SN' },
  { name: 'Serbia', code: '+381', iso2: 'RS' },
  { name: 'Singapore', code: '+65', iso2: 'SG' },
  { name: 'Slovakia', code: '+421', iso2: 'SK' },
  { name: 'Slovenia', code: '+386', iso2: 'SI' },
  { name: 'South Africa', code: '+27', iso2: 'ZA' },
  { name: 'South Korea', code: '+82', iso2: 'KR' },
  { name: 'Spain', code: '+34', iso2: 'ES' },
  { name: 'Sri Lanka', code: '+94', iso2: 'LK' },
  { name: 'Sweden', code: '+46', iso2: 'SE' },
  { name: 'Switzerland', code: '+41', iso2: 'CH' },
  { name: 'Syria', code: '+963', iso2: 'SY' },
  { name: 'Taiwan', code: '+886', iso2: 'TW' },
  { name: 'Tajikistan', code: '+992', iso2: 'TJ' },
  { name: 'Tanzania', code: '+255', iso2: 'TZ' },
  { name: 'Thailand', code: '+66', iso2: 'TH' },
  { name: 'Tunisia', code: '+216', iso2: 'TN' },
  { name: 'Turkey', code: '+90', iso2: 'TR' },
  { name: 'Turkmenistan', code: '+993', iso2: 'TM' },
  { name: 'Uganda', code: '+256', iso2: 'UG' },
  { name: 'Ukraine', code: '+380', iso2: 'UA' },
  { name: 'United Arab Emirates', code: '+971', iso2: 'AE' },
  { name: 'United Kingdom', code: '+44', iso2: 'GB' },
  { name: 'United States', code: '+1', iso2: 'US' },
  { name: 'Uruguay', code: '+598', iso2: 'UY' },
  { name: 'Uzbekistan', code: '+998', iso2: 'UZ' },
  { name: 'Venezuela', code: '+58', iso2: 'VE' },
  { name: 'Vietnam', code: '+84', iso2: 'VN' },
  { name: 'Yemen', code: '+967', iso2: 'YE' },
  { name: 'Zambia', code: '+260', iso2: 'ZM' },
  { name: 'Zimbabwe', code: '+263', iso2: 'ZW' },
];

// Function to generate flag URL
export function getFlagURL(iso2) {
  return `https://flagcdn.com/w20/${iso2.toLowerCase()}.png`; // Returns 20px wide flag
}
