const http = require('./http');

const getCountryList = () => {
	return new Promise((resolve, reject) => {
		http.get('/getCountryList').then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
		});
	});
};

const getStateList = (country) => {
	return new Promise((resolve, reject) => {
		http.get(`/getAssociatedStateList?countryGeoId=${country}`).then((data) => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		})
	})
}

const checkUserNameData = (value) => {
	return new Promise((resolve, reject) => {
		http.get(`/checkUserName?userName=${value}`).then((data) => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		})
	})
}

const checkUserNameToUpdateNewEmail = (email, partyId) => {
	return new Promise((resolve, reject) => {
		http.get(`/checkUserNameToUpdateNewEmail?userName=${email}&partyId=${partyId}`).then((data) => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		})
	})
}

module.exports = {
	getCountryList,
	getStateList,
	checkUserNameData,
	checkUserNameToUpdateNewEmail
};
